import React from 'react';
import PropTypes from 'prop-types';
import ArrowRightIcon from '../../../public/static/icons/arrow-right-gifts.svg';
import { useSelector } from 'react-redux';
import { propTypes as linkPropTypes } from '../../../components/01_atoms/Link';
import Link from '../../Link';
import GiftItem from '../../GiftItem';

import styles from './index.module.scss';

const BBPopularGiftsRow = ({ heading = '', items, link = null }) => {
  const products = useSelector((state) => state.productsStorage.gifts);
  const gifts = [];

  items.forEach((id) => {
    if (products[id]) {
      gifts.push(products[id]);
    }
  });

  const renderedProducts = gifts.map((product) => (
    <GiftItem product={product} key={product.id} variant="catalog" />
  ));
  return (
    <div className={`bb ${styles['bb-popular-gifts-row']}`}>
      <div className="container">
        <div className={styles['bb-popular-gifts-row__heading']}>
          {heading && <h3 className={styles['bb-popular-gifts-row__title']}>{heading}</h3>}
          {link?.nextLink && (
            <div>
              <Link {...link.nextLink}>
                <a>
                  {link.label}
                  <ArrowRightIcon />
                </a>
              </Link>
            </div>
          )}
        </div>
        <div className={styles['bb-popular-gifts-row__gifts']}>{renderedProducts}</div>
        <div className={styles['bb-popular-gifts-row__footer']}>
          {link?.nextLink && (
            <div>
              <Link {...link.nextLink}>
                <a>
                  {link.label}
                  <ArrowRightIcon />
                </a>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

BBPopularGiftsRow.propTypes = {
  heading: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.number).isRequired,
  link: PropTypes.shape({
    label: PropTypes.string,
    nextLink: PropTypes.shape(linkPropTypes),
  }),
};

export default BBPopularGiftsRow;
